import _generatedTable from "./generated-table";
import _util from "./util";
var exports = {};

/** @typedef {import('./generated-types').ConstantCodeMap} ConstantCodeMap */

/** @typedef {import('./generated-types').NameUint8ArrayMap} NameUint8ArrayMap */

/** @typedef {import('./generated-types').CodeNameMap} CodeNameMap */

/** @typedef {import('./generated-types').CodecName} CodecName */

/** @typedef {import('./generated-types').CodecConstant} CodecConstant */
const {
  baseTable
} = _generatedTable;
const varintEncode = _util.varintEncode;
const nameToVarint = {};
const constantToCode = {};
const codeToName = {}; // eslint-disable-next-line guard-for-in

for (const name in baseTable) {
  const codecName = name;
  const code = baseTable[codecName];
  nameToVarint[codecName] = varintEncode(code);
  const constant = codecName.toUpperCase().replace(/-/g, "_");
  constantToCode[constant] = code;

  if (!codeToName[code]) {
    codeToName[code] = codecName;
  }
}

Object.freeze(nameToVarint);
Object.freeze(constantToCode);
Object.freeze(codeToName);
const nameToCode = Object.freeze(baseTable);
exports = {
  nameToVarint,
  constantToCode,
  nameToCode,
  codeToName
};
export default exports;